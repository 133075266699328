import { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
    BackendFetch,
    DevTools,
    FormatSimple,
    Tolgee,
    TolgeeProvider,
} from "@tolgee/react";
//import { InContextTools } from "@tolgee/web/tools";

import UserMenu from "./menus/UserMenu";
import PopupContainer, {
    CURRENCY_POPUP,
    FORGOT_POPUP,
    LOGIN_POPUP,
    REGISTRATION_POPUP,
} from "./PopupContainer";
import Search from "./Search";
import usersServices from "../services/users.services";
import Header from "./Header";
import Footer from "./Footer";
import Overlay from "./Overlay";
import MobileMenu from "./menus/MobileMenu";
import { languageList, languages } from "../config/languages.config";
import Tawk from "./Tawk";
import DefaultHelmet from "./DefaultHelmet";
import { setTranslations } from "../utils/translate.utils";
import helpersUtils, { NavigateToHome } from "../utils/helpers.utils";
import { setUserData } from "../redux/user.slice";
import { setPopup } from "../redux/states.slice";
import gamesServices from "../services/games.services";
import { connectSocket } from "../services/sockets.services";

const Layout = () => {
    const { lang } = useParams();
    const location = useLocation();
    const [inited, setInited] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [cookies, setCookie] = useCookies([]);
    const tolgeeRef = useRef();
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (!languageList.includes(lang)) {
            return;
        }
        tolgeeRef.current = Tolgee()
            .use(DevTools())
            //.use(InContextTools())
            .use(FormatSimple())
            .use(
                BackendFetch({
                    prefix: "https://i18n.syncslots.com/9728405928ca28fc8b0a388e067f9e42",
                }),
            )
            .init({
                language: lang,
                availableLanguages: languageList,
                fallbackLanguage: languageList[0],
                apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
                apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
            });
    }, [lang]);

    useEffect(() => {
        if (!languageList.includes(lang)) {
            return;
        }
        const requests = [
            usersServices.init(lang),
            gamesServices.getGames(true),
        ];
        Promise.all(requests).then(async ([initData]) => {
            if (!initData) return;

            initData.translations && setTranslations(initData.translations);
            initData.userData && dispatch(setUserData(initData.userData));
            connectSocket().catch(() => {
                console.error("Error connecting socket");
            });

            setInited(true);
        });
        document.documentElement.setAttribute("lang", lang);
        helpersUtils.language = lang;
        setCookie("lang", languages[lang].language, { path: "/" });
        setCookie("locale", lang, { path: "/" });
        const bParam = urlParams.get("btag");
        const expires = new Date();
        if (bParam && !cookies["btag"]) {
            setCookie("btag", bParam, {
                expires: new Date(expires.setDate(expires.getDate() + 30)),
            });
        }
        //setAuthed(true)
    }, [lang]);

    useEffect(() => {
        if (inited) {
            const popupContent = checkHashPopups(location.hash, userData);
            popupContent && dispatch(setPopup({ popup: popupContent }));
        }
    }, [userData, inited, location.hash]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    if (!languageList.includes(lang)) {
        return <NavigateToHome />;
    }

    if (!inited) return <h1>Please wait</h1>;

    if (userData && userData.restricted) {
        return <h1 style={{ color: "white" }}>Restricted</h1>;
    }

    return (
        <TolgeeProvider tolgee={tolgeeRef.current}>
            <DefaultHelmet />
            <Overlay />
            <MobileMenu />
            <Search />
            <UserMenu />
            <Header />
            <Outlet />
            <Footer />
            <PopupContainer />
            <Tawk />
            <ToastContainer />
        </TolgeeProvider>
    );
};

function checkHashPopups(hash, authed) {
    let content = null;
    switch (hash.slice(1)) {
        case LOGIN_POPUP:
            content = authed ? null : LOGIN_POPUP;
            break;
        case REGISTRATION_POPUP:
            content = authed ? null : REGISTRATION_POPUP;
            break;
        case FORGOT_POPUP:
            content = authed ? null : FORGOT_POPUP;
            break;
        case CURRENCY_POPUP:
            content = !authed ? null : CURRENCY_POPUP;
            break;
        default:
            content = null;
    }

    return content;
}

export default Layout;
