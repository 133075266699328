import { useEffect, useState } from "react";
import { T, useTranslate } from "@tolgee/react";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator/es";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import helpersUtils from "../../utils/helpers.utils";
import usersServices from "../../services/users.services";
import SocialLogin from "../SocialLogin";
import { setPopup } from "../../redux/states.slice";
import { LOGIN_POPUP } from "../PopupContainer";
import {
    reconnectSocket,
    getTokenIfNeeded,
} from "../../services/sockets.services";

function LoginPopup() {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const defaultFields = { remember: true };
    const [fields, setFields] = useState(defaultFields);
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            if (location.hash === `#${LOGIN_POPUP}`) {
                navigate("#");
            }
        };
    }, []);

    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = { ...fields };

        newFields[name] =
            target.dataset.lowercase === "true" ? value.toLowerCase() : value;

        setFields(newFields);
        setErrors({ ...errors, [name]: undefined });
    };
    const handleBlur = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);

        const newFields = { ...fields };
        const newErrors = { ...errors };

        newFields[name] = target.dataset.trim === "true" ? value.trim() : value;

        validatorFields[name] && validatorFields[name](newFields, newErrors, t);

        setErrors(newErrors);
        setFields(newFields);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFields = { ...fields };

        const newErrors = validate(newFields);
        setErrors(newErrors);

        if (Object.keys(newErrors).length !== 0) return;

        const loggedIn = await usersServices.login(newFields);

        if (!loggedIn) {
            return;
        }

        dispatch(setPopup(null));
        toast(t("login.popup.welcome_back", "Welcome back!"));
        await usersServices.updateUserData({}, dispatch);
        await getTokenIfNeeded(true);
        await reconnectSocket();
    };

    return (
        <div className="popup__content">
            <div className="form-container">
                <div className="heading">
                    <div className="heading__content">
                        <h2>Login</h2>
                    </div>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <fieldset>
                        <label
                            className={`input-area ${errors.email ? "input-area--error" : ""}`}
                            data-label={t("login.popup.email", "Email:")}
                            data-error={errors.email || ""}
                        >
                            <input
                                name="email"
                                autoComplete="username"
                                placeholder={t("login.popup.email", "Email:")}
                                required
                                autoFocus
                                type="text"
                                className="input-area__input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fields.email || ""}
                                data-lowercase={true}
                                data-trim={true}
                                tabIndex="1"
                            />
                        </label>
                        <label
                            className={`input-area ${errors.password ? "input-area--error" : ""}`}
                            data-label={t("login.popup.password", "Password")}
                            data-error={errors.password || ""}
                        >
                            <input
                                name="password"
                                autoComplete="current-password"
                                placeholder={t(
                                    "login.popup.password",
                                    "Password",
                                )}
                                required
                                type="password"
                                className="input-area__input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fields.password || ""}
                                data-lowercase={false}
                                data-trim={false}
                                tabIndex="2"
                            />
                        </label>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                name="remember"
                                checked={fields.remember}
                                onChange={handleChange}
                                tabIndex="3"
                            />
                            <div className="checkbox__label-content">
                                <div className="checkbox__square"></div>
                                <b>
                                    <T keyName={"login.popup.remember_me"}>
                                        Remember me
                                    </T>
                                </b>
                            </div>
                        </label>
                        <div className="btn-row">
                            <label className="btn btn--medium">
                                <input type="submit" tabIndex="4" />
                                <span>
                                    <T keyName={"login.popup.login"}>Login</T>
                                </span>
                            </label>
                        </div>
                    </fieldset>
                </form>
                {/*<div className="form__info-box">
                    <Link to="#forgot" onClick={() => setPopup({ show: true, content: <ForgotPopup /> })}>
                        {_t('Forgot password')}
                    </Link>
                </div>*/}
                <br />
                <SocialLogin />
            </div>
        </div>
    );
}

const validatorFields = {
    email: (fields, errors, t, normalize = false) => {
        if (!fields.email || !validator.isEmail(fields.email)) {
            return (errors.email = t(
                "login.popup.incorrect_email_error",
                "Incorrect email",
            ));
        }
        fields.email = normalize
            ? validator.normalizeEmail(fields.email)
            : fields.email;
    },
    password: (fields, errors, t) => {
        if (!fields.password || validator.isEmpty(fields.password)) {
            return (errors.password = t(
                "login.popup.insert_password_error",
                "Please insert password",
            ));
        }
    },
};

const validate = (fields, errors = {}, t) => {
    validatorFields.email(fields, errors, t, true);
    validatorFields.password(fields, errors, t);

    return errors;
};

export default LoginPopup;
