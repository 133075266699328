import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator/es";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { T, useTranslate } from "@tolgee/react";

import paymentMethodsServices from "../../services/paymethods.services";
import StyledSelect from "../../components/ui/StyledSelect";
import helpersUtils from "../../utils/helpers.utils";
import usersServices from "../../services/users.services";
import { setPopup } from "../../redux/states.slice";
import depositsServices from "../../services/deposits.services";
import { CONFIRM_DEPOSIT_POPUP } from "../../components/PopupContainer";
import { getProfileLink } from "../../utils/links.utils";
import Select from "../../components/ui/Select";
import projectsServices from "../../services/projects.services";
import playersServices from "../../services/players.services";

const AccountDeposit = () => {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [availableCurrencies, setAvailableCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(
        userData.player.currency_id,
    );
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState("");
    const [movedDown, setMovedDown] = useState(false);
    const isProfileCompleted =
        userData && usersServices.isProfileCompleted(userData);
    const navigate = useNavigate();

    useEffect(() => {
        projectsServices.getAvailableCurrencies().then((data) => {
            data && setAvailableCurrencies(data);
        });
    }, []);

    useEffect(() => {
        playersServices.activate(parseInt(selectedCurrency)).then(() => {
            usersServices.updateUserData(userData, dispatch);
        });
    }, [selectedCurrency]);

    useEffect(() => {
        paymentMethodsServices.getPaymentMethods().then((data) => {
            data && setPaymentMethods(data);
            data && setSelectedPaymentMethod(data[0]);
        });
        setSelectedCurrency(userData.player.currency_id);
    }, [userData.player.currency_id]);

    useEffect(() => {
        if (!selectedPaymentMethod) return;
        setAmount(
            helpersUtils.unitAmount(
                selectedPaymentMethod.min * 5,
                userData.player.currency_subunit,
            ),
        );
    }, [selectedPaymentMethod]);

    let tooltipText = "";
    let clickAction = () => {};
    if (!isProfileCompleted) {
        tooltipText = t(
            "account.deposit.complete_profile_tooltip_message",
            "Please complete your profile before making a deposit",
        );
        clickAction = () => {
            toast(tooltipText);
            navigate(getProfileLink());
        };
    }

    const validateAmount = (value) => {
        if (value && !validator.isFloat(value.toString())) {
            setAmountError(
                t("account.deposit.invalid_amount", "Invalid amount"),
            );
            return false;
        }
        return true;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isProfileCompleted) {
            clickAction();
            return;
        }

        validateAmount(amount);

        if (amountError) return;

        const depositData = await depositsServices.createDeposit({
            amount: helpersUtils.subunitAmount(
                amount,
                userData.player.currency_subunit,
            ),
            payment_method_id: selectedPaymentMethod.id,
        });
        if (depositData.iframe) {
            //todo show iframe
        }
        dispatch(
            setPopup({
                popup: CONFIRM_DEPOSIT_POPUP,
                extraProps: { url: depositData.url },
            }),
        );
    };

    if (!paymentMethods || !selectedPaymentMethod || !availableCurrencies)
        return null;

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>
                                <T keyName="account.deposit.deposit_title">
                                    Deposit
                                </T>
                            </h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="tab">
                                {/*<div className="tab__menu tab__menu--payment">
                                    <div className="tab__menu-item tab__menu-item--active" data-tab="tab0">
                                        <div className="tab__menu-btn"><span>Bank card</span></div>
                                    </div>
                                    <div className="tab__menu-item" data-tab="tab1">
                                        <div className="tab__menu-btn"><span>Cripto</span></div>
                                    </div>
                                    <div className="tab__menu-item" data-tab="tab2">
                                        <div className="tab__menu-btn"><span>Переводы</span></div>
                                    </div>
                                </div>*/}
                                <div className="tab__content">
                                    <div>
                                        <form
                                            className="form"
                                            onSubmit={handleSubmit}
                                        >
                                            <fieldset>
                                                <div className="form__grid">
                                                    <div
                                                        className={`payment-catalog ${movedDown ? "last-order" : ""}`}
                                                    >
                                                        <div className="payment-catalog__grid">
                                                            {paymentMethods.map(
                                                                (method) => {
                                                                    return (
                                                                        <label
                                                                            key={
                                                                                method.id
                                                                            }
                                                                            className={`payment-card ${method.id === selectedPaymentMethod.id ? "payment-card--active" : ""}`}
                                                                        >
                                                                            <input
                                                                                className="payment-card__radio-input"
                                                                                type="radio"
                                                                                name="deposit"
                                                                                onClick={() => {
                                                                                    setSelectedPaymentMethod(
                                                                                        method,
                                                                                    );
                                                                                    setMovedDown(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <div className="payment-card__body">
                                                                                <div className="payment-card__title">
                                                                                    <div className="payment-card__title-img-box">
                                                                                        <img
                                                                                            src={
                                                                                                method.custom_image1 ||
                                                                                                method.image1
                                                                                            }
                                                                                            alt={
                                                                                                method.title
                                                                                            }
                                                                                            className="payment-card__title-img"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="account__body">
                                                        <div className="account__shape account__shape--large">
                                                            <div className="account__form-grid">
                                                                <div className="account__form-grid-order-1">
                                                                    <div className="payment-card payment-card--form">
                                                                        <div className="payment-card__body">
                                                                            <div className="payment-card__title">
                                                                                <div className="payment-card__title-img-box">
                                                                                    <img
                                                                                        src={
                                                                                            selectedPaymentMethod.custom_image1 ||
                                                                                            selectedPaymentMethod.image1
                                                                                        }
                                                                                        alt={
                                                                                            selectedPaymentMethod.name
                                                                                        }
                                                                                        className="payment-card__title-img"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="account__form-grid-order-2">
                                                                    <div className="account__shape">
                                                                        <label
                                                                            className={`input-area input-area--true-row input-area--select ${amountError && "input-area--error"}`}
                                                                            data-label={`${t("account.deposit.enter_deposit_amount", "Enter deposit amount")}:`}
                                                                            data-error={
                                                                                amountError
                                                                            }
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                className="input-area__input"
                                                                                placeholder={t(
                                                                                    "account.deposit.enter_deposit_amount",
                                                                                    "Enter deposit amount",
                                                                                )}
                                                                                onChange={(
                                                                                    event,
                                                                                ) => {
                                                                                    let [
                                                                                        ,
                                                                                        value,
                                                                                    ] =
                                                                                        helpersUtils.getFormEventData(
                                                                                            event,
                                                                                        );
                                                                                    value =
                                                                                        value.replace(
                                                                                            /[^0-9.]/g,
                                                                                            "",
                                                                                        );
                                                                                    let t = 0;
                                                                                    value =
                                                                                        value.replace(
                                                                                            /./g,
                                                                                            (
                                                                                                match,
                                                                                            ) =>
                                                                                                match ===
                                                                                                    "." &&
                                                                                                ++t >
                                                                                                    1
                                                                                                    ? ""
                                                                                                    : match,
                                                                                        );
                                                                                    setAmount(
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                                onBlur={(
                                                                                    event,
                                                                                ) => {
                                                                                    const [
                                                                                        ,
                                                                                        value,
                                                                                    ] =
                                                                                        helpersUtils.getFormEventData(
                                                                                            event,
                                                                                        );
                                                                                    validateAmount(
                                                                                        value,
                                                                                    ) &&
                                                                                        setAmountError(
                                                                                            "",
                                                                                        );
                                                                                }}
                                                                                value={
                                                                                    amount
                                                                                }
                                                                            />
                                                                            <Select
                                                                                customSelectClass="input-area__select"
                                                                                name="type"
                                                                                options={availableCurrencies.map(
                                                                                    (
                                                                                        currency,
                                                                                    ) => {
                                                                                        return {
                                                                                            name: currency.code,
                                                                                            value: currency.id,
                                                                                        };
                                                                                    },
                                                                                )}
                                                                                selectedValue={
                                                                                    selectedCurrency
                                                                                }
                                                                                setValue={(
                                                                                    value,
                                                                                ) => {
                                                                                    setSelectedCurrency(
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="account__form-grid-order-3">
                                                                    <div className="account__shape">
                                                                        <StyledSelect
                                                                            options={paymentMethods.map(
                                                                                (
                                                                                    method,
                                                                                ) => ({
                                                                                    value: method.id,
                                                                                    label: method.name,
                                                                                }),
                                                                            )}
                                                                            title={t(
                                                                                "account.deposit.payment_method",
                                                                                "Payment method",
                                                                            )}
                                                                            fieldName="paymethod_id"
                                                                            error=""
                                                                            selectedValue={
                                                                                selectedPaymentMethod.id
                                                                            }
                                                                            setValue={(
                                                                                value,
                                                                            ) => {
                                                                                setSelectedPaymentMethod(
                                                                                    paymentMethods.find(
                                                                                        (
                                                                                            method,
                                                                                        ) =>
                                                                                            method.id ===
                                                                                            value,
                                                                                    ),
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="account__form-grid-order-5">
                                                                    <div className="account__shape">
                                                                        <label className="checkbox checkbox--alert">
                                                                            <input type="checkbox" />
                                                                            <div className="checkbox__label-content">
                                                                                <div className="checkbox__square"></div>
                                                                                <div className="row row--between">
                                                                                    <b>
                                                                                        <T keyName="account.deposit.welcome_bonus">
                                                                                            Welcome
                                                                                            bonus
                                                                                        </T>
                                                                                    </b>
                                                                                    <b>
                                                                                        +
                                                                                        {
                                                                                            amount
                                                                                        }{" "}
                                                                                        {userData.player.currency_code.toUpperCase()}
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                        {/*<label className="checkbox checkbox--alert">
                                                                            <input type="checkbox" checked="" />
                                                                                <div
                                                                                    className="checkbox__label-content">
                                                                                    <div
                                                                                        className="checkbox__square"></div>
                                                                                    <div className="row row--between">
                                                                                        <b>Промо-код:</b>
                                                                                        <b>+20 FS</b>
                                                                                    </div>
                                                                                </div>
                                                                        </label>*/}
                                                                        {/*<div
                                                                            className="hide-input-area hide-input-area--button">
                                                                            <div className="hide-input-area__trigger">У
                                                                                меня
                                                                                есть
                                                                                промо-код
                                                                            </div>
                                                                            <label className="input-area"
                                                                                   data-label="Введите промо-код"
                                                                                   data-error="Введите промо-код">
                                                                                <input type="text"
                                                                                       className="input-area__input"
                                                                                       placeholder="Введите промо-код" />
                                                                            </label>
                                                                        </div>*/}
                                                                    </div>
                                                                </div>
                                                                <div className="account__form-grid-order-4">
                                                                    <div className="btn-row">
                                                                        <label
                                                                            className="btn btn--medium"
                                                                            data-tooltip-id="deposit"
                                                                            data-tooltip-html={
                                                                                tooltipText
                                                                            }
                                                                        >
                                                                            <input type="submit" />
                                                                            <span>
                                                                                <T keyName="account.deposit.deposit_button">
                                                                                    Deposit
                                                                                </T>
                                                                            </span>
                                                                        </label>
                                                                        {tooltipText && (
                                                                            <Tooltip id="deposit" />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="account__form-grid-order-6">
                                                                    <div className="account__shape">
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <T keyName="account.deposit.commision">
                                                                                            Commission
                                                                                        </T>
                                                                                        :
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <b>
                                                                                            0%
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <T keyName="account.deposit.processing_time">
                                                                                            Processing
                                                                                            time
                                                                                        </T>
                                                                                        :
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <b>
                                                                                            <T keyName="account.deposit.instant">
                                                                                                Instant
                                                                                            </T>
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <T keyName="account.deposit.minimum_deposit">
                                                                                            Minimum
                                                                                            deposit
                                                                                        </T>
                                                                                        :
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <b>
                                                                                            {helpersUtils.unitAmount(
                                                                                                selectedPaymentMethod.min,
                                                                                                userData
                                                                                                    .player
                                                                                                    .currency_subunit,
                                                                                            )}{" "}
                                                                                            {userData.player.currency_code.toUpperCase()}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <T keyName="account.deposit.maximum_deposit">
                                                                                            Maximum
                                                                                            deposit
                                                                                        </T>
                                                                                        :
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <b>
                                                                                            {helpersUtils.unitAmount(
                                                                                                selectedPaymentMethod.max,
                                                                                                userData
                                                                                                    .player
                                                                                                    .currency_subunit,
                                                                                            )}{" "}
                                                                                            {userData.player.currency_code.toUpperCase()}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountDeposit;
