import { io } from "socket.io-client";
import usersServices from "./users.services";
import reduxStore from "../redux/store";
import { setUserData } from "../redux/user.slice";
import { addMinutes } from "date-fns";

let socket;
let tokenData = {};

socket = io("/", {
    transports: ["websocket"], // Ensure WebSocket is the transport
    path: "/wss/socket.io",
    autoConnect: false, // Disable immediate connection
    reconnection: false,
});

export async function getTokenIfNeeded(force = false) {
    if (tokenData.token && tokenData.expires > Date.now() && !force) return;
    tokenData = {};
    try {
        tokenData = await usersServices.getWsToken();
    } catch (err) {
        console.error("Failed to get WebSocket token:", err);
    }
}

export async function connectSocket() {
    if (socket.connected) return;

    await getTokenIfNeeded();

    if (!tokenData.token) {
        return setTimeout(() => {
            connectSocket();
        }, 1000);
    }

    socket.io.opts.query = { token: tokenData.token };
    socket.connect();
}

export async function reconnectSocket() {
    if (socket.connected) {
        socket.disconnect();
    }
    await connectSocket();
}

// Set up event handlers:
socket.on("connect", () => {
    console.log("Socket connected with token:", socket.io.opts.query.token);
});

socket.on("connect_error", async (error) => {
    console.log("connect_error:", error.message);

    // Example error handling: if it's a token-related or any error, just reconnect
    // with a fresh token. You could check error.message for "Token expired".
    await reconnectSocket();
});

socket.on("disconnect", async (reason) => {
    console.log("Socket disconnected:", reason);

    // Potentially reconnect with a fresh token if this wasn't an intentional disconnect.
    // Typical reasons: "io server disconnect", "ping timeout", "transport close".
    // Decide which ones you want to handle.
    if (reason !== "io client disconnect") {
        await reconnectSocket();
    }
});

socket.on("message", (message) => {
    switch (message.type) {
        case "balance_update":
            handleBalanceMessage(message.data);
            break;
        default:
            console.log("Unknown message type:", message.type);
    }
    console.log("Message received:", message);
});

const handleBalanceMessage = (data) => {
    const state = reduxStore.getState();
    const oldUserData = state.user.userData;

    if (oldUserData.player.currency_id !== data.currency_id) {
        return;
    }

    const newData = structuredClone(oldUserData);
    newData.player.balance = data.balance;

    reduxStore.dispatch(setUserData(newData));
};
