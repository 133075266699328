import React, { useCallback, useEffect, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";

import kycServices from "../../services/kyc.services";

const AccountVerificationSumSub = () => {
    const [accessToken, setAccessToken] = useState("");

    useEffect(() => {
        kycServices.getSumsubAccessToken().then(({ token }) => {
            console.log("token", token);
            if (!token) {
                return;
            }
            setAccessToken(token);
        });
    }, []);

    const accessTokenExpirationHandler = useCallback(async () => {
        kycServices.getSumsubAccessToken().then(({ token }) => {
            console.log("token", token);
            if (!token) {
                return;
            }
            setAccessToken(token);
        });
    }, []);

    console.log("sumsub");

    return (
        <main className="main" style={{ height: "100vh" }}>
            {accessToken ? (
                <SumsubWebSdk
                    accessToken={accessToken}
                    expirationHandler={accessTokenExpirationHandler}
                    config={{ theme: "dark" }}
                    onMessage={(message) => {
                        console.log("WebSDK Message:", message);
                    }}
                    onError={(error) => {
                        console.error("WebSDK Error:", error);
                    }}
                    className="sumsub-iframe"
                />
            ) : null}
        </main>
    );
};

export default AccountVerificationSumSub;
