import { addDays, format, intervalToDuration } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { T, useTranslate } from "@tolgee/react";

import withdrawalsServices from "../../services/withdrawals.services";
import helpersUtils from "../../utils/helpers.utils";
import usersServices from "../../services/users.services";

const PENDING_STATUS = 1;
const BATCH_SIZE = 5;

const AccountCancelWithdraw = () => {
    const { t } = useTranslate();
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const [withdrawals, setWithdrawals] = useState(null);
    const [page] = useState(1);
    const [perPage, setPerPage] = useState(BATCH_SIZE);
    const [refreshWithdrawal, setRefreshWithdrawal] = useState(0);

    useEffect(() => {
        const filters = {
            p: {
                page,
                per_page: perPage,
            },
            f: {
                status: PENDING_STATUS,
                player_id: userData.player.id,
            },
        };
        withdrawalsServices.index(filters).then((data) => {
            if (data) {
                setWithdrawals(data);
            }
        });
    }, [page, perPage, refreshWithdrawal]);

    const handelCancel = (uuid) => {
        if (
            !window.confirm(
                t(
                    "account.cancel.withdraw.cancel_withdrawal_confirmation_message",
                    "Are you sure you want to cancel the withdrawal request?",
                ),
            )
        ) {
            return;
        }
        withdrawalsServices.cancel(uuid).then(() => {
            setRefreshWithdrawal((counter) => ++counter);
            toast.success(
                t(
                    "account.cancel.withdraw.message_success",
                    "Withdrawal request canceled",
                ),
            );
            usersServices.updateUserData(userData, dispatch); //no need to wait for response
        });
    };

    const renderLeftTimeParts = (value) => {
        return value > 9 ? value.toString() : `0${value}`;
    };

    const renderTableBody = () => {
        if (!withdrawals?.data) {
            return null;
        }
        return (
            <tbody>
                {withdrawals?.data?.map((withdrawal) => {
                    let duration = intervalToDuration({
                        start: new Date(),
                        end: addDays(new Date(withdrawal.created_at), 1),
                    });
                    return (
                        <tr key={withdrawal.uuid}>
                            <td>
                                <div
                                    className="small-btn ttu"
                                    onClick={() =>
                                        handelCancel(withdrawal.uuid)
                                    }
                                >
                                    {t(
                                        "account.cancel.withdraw.cancel",
                                        "Cancel",
                                    )}
                                </div>
                            </td>
                            <td>
                                {helpersUtils.unitAmount(
                                    withdrawal.amount,
                                    userData.player.currency_subunit,
                                    userData.player.currency_decimal_digits,
                                )}{" "}
                                {userData.player.currency_code}
                            </td>
                            <td>
                                {renderLeftTimeParts(duration.hours)}:
                                {renderLeftTimeParts(duration.minutes)}:
                                {renderLeftTimeParts(duration.seconds)}
                            </td>
                            <td>
                                {format(
                                    new Date(withdrawal.created_at),
                                    "HH:mm:ss / dd.MM.yyyy",
                                )}
                            </td>
                            <td>{withdrawal.paymethod}</td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>
                                <T keyName="account.cancel.withdraw.cancel_withdraw_title">
                                    Withdrawal cancel
                                </T>
                            </h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <T keyName="account.cancel.withdraw.cancel_withdraw_table_amount">
                                                            Amount
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.cancel.withdraw.cancel_withdraw_table_time_left">
                                                            Time left
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.cancel.withdraw.cancel_withdraw_table_created">
                                                            Created
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.cancel.withdraw.cancel_withdraw_payment_method">
                                                            Payment method
                                                        </T>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {renderTableBody()}
                                        </table>
                                    </div>
                                    {withdrawals?.total > perPage && (
                                        <div className="btn-row">
                                            <div
                                                className="btn btn--black"
                                                onClick={() => {
                                                    setPerPage(
                                                        (prev) =>
                                                            prev + BATCH_SIZE,
                                                    );
                                                }}
                                            >
                                                <span>
                                                    <T keyName="pagination.show_more">
                                                        Show more
                                                    </T>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountCancelWithdraw;
