import requestUtils from "../utils/request.utils";

const kycServices = {};

kycServices.getSumsubAccessToken = async () => {
    try {
        return requestUtils.get("/kyc/sumsub/token", {}, true);
    } catch (err) {
        console.error(err);
    }
};

export default kycServices;
