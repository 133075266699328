import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { T } from "@tolgee/react";

import usersServices from "../../services/users.services";
import helpersUtils from "../../utils/helpers.utils";
import { setPopup, setShowUserMenu } from "../../redux/states.slice";
import { setUserData } from "../../redux/user.slice";
import { LOGOUT_POPUP } from "../PopupContainer";
import {
    getTokenIfNeeded,
    reconnectSocket,
} from "../../services/sockets.services";

const LogoutPopup = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            if (location.hash === `#${LOGOUT_POPUP}`) {
                navigate("#");
            }
        };
    }, []);

    const logout = async () => {
        const success = await usersServices.logout();
        dispatch(setPopup(null));
        dispatch(setUserData(null));
        dispatch(setShowUserMenu(false));
        if (success) {
            await getTokenIfNeeded(true);
            await reconnectSocket();
            location.href = helpersUtils.getLanguageLink("/");
        }
    };

    return (
        <div className="popup__content">
            <div className="heading">
                <div className="heading__content">
                    <h2>
                        <T keyName={"logout.popup.log_out"}>Log out</T>
                    </h2>
                </div>
            </div>
            <div>
                <T keyName={"logout.popup.log_out_question"}>
                    Do you really want to log out?
                </T>
            </div>
            <div className="btn-row">
                <div className="btn btn--small btn--black" onClick={logout}>
                    <span>
                        <T keyName={"logout.popup.yes"}>Yes</T>
                    </span>
                </div>
                <div
                    className="btn btn--small"
                    onClick={() => {
                        dispatch(setPopup(null));
                    }}
                >
                    <span>
                        <T keyName={"logout.popup.no"}>No</T>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LogoutPopup;
