import {useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {useSelector} from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import mainRoutes from './routes/main.routes';


function App() {
    const userData = useSelector((state) => state.user.userData);

    useEffect(() => {
        userData ? document.body.classList.add('on-line') : document.body.classList.remove('on-line');
    }, [userData]);

    return (
        <RouterProvider router={mainRoutes} />
    );
}

export default App;
