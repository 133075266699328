import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { T, useTranslate } from "@tolgee/react";

import SmallButton from "../../components/ui/SmallButton";
import bonusesServices from "../../services/bonuses.services";
import freespinsServices from "../../services/freespins.services";
import gamesServices from "../../services/games.services";
import helpersUtils from "../../utils/helpers.utils";

// Placeholder local Arr
// const placeholderCasinoBonuses = [
//     {
//         id: 0,
//         imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/02.jpg',
//         title: '100% приветсвенный бонус до 150000 рублей на депозит',
//         info: 'В SimCasino каждый найдет бонус на свой вкус. Соверши первое пополнение счета и получи 100% бонус, а также до 50 фриспинов в подарок на второй день!',
//     },
//     {
//         id: 1,
//         imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/03.jpg',
//         title: '200% приветсвенный бонус до 150000 рублей на депозит',
//         info: '',
//     },
//     {
//         id: 2,
//         imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/02.jpg',
//         title: '150% приветсвенный бонус до 150000 рублей на депозит',
//         info: 'В SimCasino каждый найдет бонус на свой вкус. Соверши первое пополнение счета и получи 100% бонус, а также до 50 фриспинов в подарок на второй день!',
//     },
// ];

const BATCH_SIZE = 5;

const AccountCasinoBonuses = () => {
    const { t } = useTranslate();
    const { lang: currentLanguage } = useParams();

    const userData = useSelector((state) => state.user.userData);
    // const [casinoBonuses, setCasinoBonuses] = useState(null);
    const [bonuses, setBonuses] = useState(null);
    const [spins, setSpins] = useState(null);
    const [page] = useState(1);
    const [perPage, setPerPage] = useState(BATCH_SIZE);
    const [spinsPage] = useState(1);
    const [spinsPerPage, setSpinsPerPage] = useState(BATCH_SIZE);

    const [refreshBonus, setRefreshBonus] = useState(0);
    const [refreshSpins, setRefreshSpins] = useState(0);

    useEffect(() => {
        const bonusFilters = {
            p: {
                page: page,
                per_page: perPage,
            },
            f: {
                player_id: userData?.player.id,
            },
        };
        bonusesServices.getPlayerBonuses(bonusFilters).then((bonuses) => {
            if (!bonuses) {
                return;
            }

            const bonusesToActivate = [];
            const bonusesCanceledOrExpired = [];
            let activeBonus;
            bonuses.data.forEach((bonus, index) => {
                if (index === 0 && bonus.active) {
                    activeBonus = bonus;
                    return;
                }
                if (
                    !bonus.canceled &&
                    !bonus.used &&
                    !(
                        format(
                            new Date(bonus.expires_at),
                            "dd/MM/yyyy HH:mm:ss",
                        ) < format(new Date(Date.now()), "dd/MM/yyyy HH:mm:ss")
                    )
                ) {
                    bonusesToActivate.push(bonus);
                } else {
                    bonusesCanceledOrExpired.push(bonus);
                }
            });
            if (activeBonus) {
                setBonuses({
                    data: [
                        activeBonus,
                        ...bonusesToActivate,
                        ...bonusesCanceledOrExpired,
                    ],
                    total: bonuses.total,
                });
            } else {
                setBonuses({
                    data: [...bonusesToActivate, ...bonusesCanceledOrExpired],
                    total: bonuses.total,
                });
            }
        });
    }, [page, perPage, refreshBonus]);

    useEffect(() => {
        const spinsFilters = {
            p: {
                page: spinsPage,
                per_page: spinsPerPage,
            },
            f: {
                player_id: userData?.player?.id,
            },
        };
        freespinsServices.getPlayerFreeSpines(spinsFilters).then((spins) => {
            if (!spins) {
                return null;
            }
            setSpins(spins);
        });
    }, [spinsPage, spinsPerPage, refreshSpins]);

    const handleCancelSpin = (uuid) => {
        if (
            !window.confirm(
                t(
                    "account.casino.bonuses.cancel_spin_confirmation_message",
                    "Are you sure you want to cancel this spin?",
                ),
            )
        ) {
            return;
        }
        freespinsServices.cancel(uuid).then((game) => {
            setRefreshSpins((counter) => ++counter);
        });
    };

    const checkDisabledGame = (spin) => {
        try {
            const game = gamesServices.getByGameId(spin.game_id);
            if (!game) {
                return true;
            }
        } catch (err) {
            return true;
        }

        return false;
    };

    const handleDeactivate = (uuid) => {
        if (
            !window.confirm(
                t(
                    "account.casino.bonuses.deactivate_bonus_message",
                    "Are you sure you want to deactivate this bonus?",
                ),
            )
        ) {
            return;
        }
        bonusesServices.deactivate(uuid).then(() => {
            setRefreshBonus((counter) => ++counter);
        });
    };

    const handleActivate = (uuid) => {
        if (
            !window.confirm(
                t(
                    "account.casino.bonuses.activate_bonus_message",
                    "Are you sure you want to activate this bonus?",
                ),
            )
        ) {
            return;
        }
        bonusesServices.activate(uuid).then(() => {
            setRefreshBonus((counter) => ++counter);
        });
    };

    const renderBonusButtons = (bonus) => {
        const now = new Date();
        if (bonus.active && new Date(bonus.expires_at) > now) {
            return (
                <SmallButton black onClick={() => handleDeactivate(bonus.uuid)}>
                    <span>
                        <T keyName="account.casino.bonuses.cancel_button">
                            Cancel
                        </T>
                    </span>
                </SmallButton>
            );
        }
        if (
            !bonus.canceled &&
            !bonus.used &&
            new Date(bonus.expires_at) > now
        ) {
            return (
                <SmallButton onClick={() => handleActivate(bonus.uuid)}>
                    <span>
                        <T keyName="account.casino.bonuses.activate_button">
                            Activate
                        </T>
                    </span>
                </SmallButton>
            );
        }
        return (
            <SmallButton disabled>
                <span>
                    <T keyName="account.casino.bonuses.activate_button">
                        Activate
                    </T>
                </span>
            </SmallButton>
        );
    };

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>
                                <T keyName="account.casino.bonuses.casino_bonuses_page_header">
                                    Casino bonuses
                                </T>
                            </h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            {/*<div className="account__bonus-grid">*/}
                            {/*    {*/}
                            {/*        casinoBonuses.map((bonus) => {*/}
                            {/*            return (*/}
                            {/*                <BonusCard*/}
                            {/*                    key={bonus.title}*/}
                            {/*                    vertical*/}
                            {/*                    imgUrl={bonus.imgUrl}*/}
                            {/*                    title={bonus.title}*/}
                            {/*                    info={bonus.info}*/}
                            {/*                >*/}
                            {/*                    <SmallButton>*/}
                            {/*                        <span>{_t('activate')}</span>*/}
                            {/*                    </SmallButton>*/}
                            {/*                    <SmallButton black>*/}
                            {/*                        <span>{_t('refuse')}</span>*/}
                            {/*                    </SmallButton>*/}
                            {/*                </BonusCard>*/}
                            {/*            );*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="heading">
                                        <div className="heading__content">
                                            <h2>
                                                <T keyName="account.casino.bonuses.bonuses_title">
                                                    Bonuses
                                                </T>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.received_expires_header">
                                                            Received / expires
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.bonus_amount_header">
                                                            Amount
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.bonus_wagering_header">
                                                            Bonus wagering
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.bonus_rollover_header">
                                                            Rollover
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.bonus_played_header">
                                                            Played
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.bonus_canceled_header">
                                                            Canceled
                                                        </T>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {bonuses?.data && (
                                                <tbody>
                                                    {bonuses?.data?.map(
                                                        (bonus) => {
                                                            return (
                                                                <tr
                                                                    key={
                                                                        bonus.uuid
                                                                    }
                                                                >
                                                                    <td>
                                                                        <div className="btn-row btn-row--no-padding">
                                                                            {renderBonusButtons(
                                                                                bonus,
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {format(
                                                                            new Date(
                                                                                bonus.created_at,
                                                                            ),
                                                                            "dd/MM/yyyy HH:mm:ss",
                                                                        )}
                                                                        <br />
                                                                        {format(
                                                                            new Date(
                                                                                bonus.expires_at,
                                                                            ),
                                                                            "dd/MM/yyyy HH:mm:ss",
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {helpersUtils.unitAmount(
                                                                            bonus.amount,
                                                                            bonus.currency_subunit,
                                                                            bonus.currency_decimal_digits,
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            bonus.wager
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {helpersUtils.unitAmount(
                                                                            bonus.rollover,
                                                                            bonus.currency_subunit,
                                                                            bonus.currency_decimal_digits,
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {bonus.used ? (
                                                                            <T keyName="account.casino.bonuses.played_yes">
                                                                                Yes
                                                                            </T>
                                                                        ) : (
                                                                            <T keyName="account.casino.bonuses.played_no">
                                                                                No
                                                                            </T>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {bonus.canceled ? (
                                                                            <T keyName="account.casino.bonuses.canceled_yes">
                                                                                Yes
                                                                            </T>
                                                                        ) : (
                                                                            <T keyName="account.casino.bonuses.played_no">
                                                                                No
                                                                            </T>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        },
                                                    )}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    {bonuses?.total > perPage && (
                                        <div className="btn-row">
                                            <div
                                                className="btn btn--black"
                                                onClick={() => {
                                                    setPerPage(
                                                        (prev) =>
                                                            prev + BATCH_SIZE,
                                                    );
                                                }}
                                            >
                                                <span>
                                                    <T keyName="pagination.show_more">
                                                        Show more
                                                    </T>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                    <p>
                                        <T keyName="account.casino.bonuses.bonus_activation_info">
                                            Enabling a new bonus will deactivate
                                            any currently active bonus
                                        </T>
                                    </p>
                                </div>
                            </div>

                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="heading">
                                        <div className="heading__content">
                                            <h2>
                                                <T keyName="pagination.show_more">
                                                    Show more
                                                </T>
                                                {t(
                                                    "account.casino.bonuses.free_spins_title",
                                                    "Free spins",
                                                )}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.free_spins_quantity_header">
                                                            Quantity
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.free_spins_used_spins_header">
                                                            Used spins
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.Amount won">
                                                            Show more
                                                        </T>
                                                    </th>
                                                    <th>
                                                        <T keyName="account.casino.bonuses.received_expires_header">
                                                            Received / expires
                                                        </T>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {spins?.data && (
                                                <tbody>
                                                    {spins?.data?.map(
                                                        (spin) => {
                                                            const game =
                                                                gamesServices.getByGameId(
                                                                    spin.game_id,
                                                                );
                                                            const gameSlug =
                                                                game
                                                                    ? gamesServices.getSlug(
                                                                          game.name,
                                                                          game.id,
                                                                      )
                                                                    : "";
                                                            const gameLink =
                                                                game
                                                                    ? gamesServices.getPlayLink(
                                                                          gameSlug,
                                                                          currentLanguage,
                                                                      )
                                                                    : "";
                                                            const isCancelable =
                                                                !spin.canceled &&
                                                                spin.used_spins ===
                                                                    0;
                                                            return (
                                                                <tr
                                                                    key={
                                                                        spin.uuid
                                                                    }
                                                                >
                                                                    <td>
                                                                        <div className="btn-row btn-row--no-padding">
                                                                            <SmallButton
                                                                                disabled={
                                                                                    !isCancelable
                                                                                }
                                                                                onClick={() =>
                                                                                    handleCancelSpin(
                                                                                        spin.uuid,
                                                                                    )
                                                                                }
                                                                                black
                                                                            >
                                                                                <span>
                                                                                    {spin.canceled ? (
                                                                                        <T keyName="account.casino.bonuses.canceled_text">
                                                                                            Canceled
                                                                                        </T>
                                                                                    ) : (
                                                                                        <T keyName="account.casino.bonuses.cancel_button">
                                                                                            Cancel
                                                                                        </T>
                                                                                    )}
                                                                                </span>
                                                                            </SmallButton>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            spin.quantity
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            spin.used_spins
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            spin.amount_won
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {format(
                                                                            new Date(
                                                                                spin.created_at,
                                                                            ),
                                                                            "dd/MM/yyyy HH:mm:ss",
                                                                        )}
                                                                        <br />
                                                                        {format(
                                                                            new Date(
                                                                                spin.expires_at,
                                                                            ),
                                                                            "dd/MM/yyyy HH:mm:ss",
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn-row btn-row--no-padding">
                                                                            {gameLink && (
                                                                                <Link
                                                                                    to={
                                                                                        gameLink
                                                                                    }
                                                                                    className={`small-btn ${!game ? "small-btn--disabled" : ""}`}
                                                                                >
                                                                                    <span>
                                                                                        <T keyName="account.casino.bonuses.play">
                                                                                            Play
                                                                                        </T>
                                                                                    </span>
                                                                                </Link>
                                                                            )}
                                                                            {/*<SmallButton disabled={} onClick={() => handlePlay(spin.game_id)}>
                                                                            <span>{_t('Play')}</span>
                                                                        </SmallButton>*/}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        },
                                                    )}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                    {spins?.total > spinsPerPage && (
                                        <div className="btn-row">
                                            <div
                                                className="btn btn--black"
                                                onClick={() => {
                                                    setSpinsPerPage(
                                                        (prev) =>
                                                            prev + BATCH_SIZE,
                                                    );
                                                }}
                                            >
                                                <span>
                                                    <T keyName="pagination.show_more">
                                                        Show more
                                                    </T>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountCasinoBonuses;
