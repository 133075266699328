import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { T } from "@tolgee/react";

import { CURRENCY_POPUP } from "../PopupContainer";
import projectsServices from "../../services/projects.services";
import playersServices from "../../services/players.services";
import usersServices from "../../services/users.services";

function CurrencyPopup() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [fiatCurrencies, setFiatCurrencies] = useState([]);
    const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(
        userData.player.currency_id,
    );

    useEffect(() => {
        projectsServices.getAvailableCurrencies().then((data) => {
            if (data) {
                const fiat = [];
                const crypto = [];

                data.forEach((currency) => {
                    if (currency.crypto) {
                        crypto.push(currency);
                    } else {
                        fiat.push(currency);
                    }
                });
                setFiatCurrencies(fiat);
                setCryptoCurrencies(crypto);
            }
        });
    }, []);

    useEffect(() => {
        playersServices.activate(selectedCurrency).then(() => {
            usersServices.updateUserData(userData, dispatch).catch();
            navigate("#");
        });
    }, [selectedCurrency]);

    useEffect(() => {
        return () => {
            if (location.hash === `#${CURRENCY_POPUP}`) {
                navigate("#");
            }
        };
    }, []);

    return (
        <div className="popup__content">
            <div className="heading">
                <div className="heading__content">
                    <h2>
                        <T keyName="currency.popup.change_currency">
                            Change currency
                        </T>
                    </h2>
                </div>
            </div>
            <div className="currency">
                <div className="currency__row">
                    {fiatCurrencies.map((currency) => {
                        const active =
                            userData?.player?.currency_id === currency?.id;
                        return (
                            <div
                                className={`currency-item ${active ? "currency-item--active" : ""}`}
                                key={currency.id}
                                onClick={() => setSelectedCurrency(currency.id)}
                            >
                                <div className="currency-item__icon">
                                    {currency?.symbol}
                                </div>
                                <div className="currency-item__name">
                                    {currency?.code}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <h4 className="ttu">Crypto</h4>
                <div className="currency__row">
                    {cryptoCurrencies.map((currency) => {
                        const active =
                            userData?.player?.currency_id === currency?.id;
                        return (
                            <div
                                className={`currency-item ${active ? "currency-item--active" : ""}`}
                                key={currency.id}
                                onClick={() => setSelectedCurrency(currency.id)}
                            >
                                <div className="currency-item__icon">
                                    {currency?.symbol}
                                </div>
                                <div className="currency-item__name">
                                    {currency?.code}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CurrencyPopup;
